.App {
  text-align: center;
  background-color: black;
}

.image {
  height: 100%;
  width: 100%;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: black;
}

#screen {
  display: block;
  position: absolute;
  background-color: black;
  height: 3000px;
  width: 100%;
}

/* Media query for landscape orientation */
@media screen and (orientation: landscape) {
  #screen {
    height: 6000px;
    width: 150%;
    zoom: 0.5;
  }
}

#matrixRain {
  position: absolute;
  height: 3000px;
  width: 100%;
  top: 0%;
  left: 0%;
}

/* Media query for landscape orientation */
@media screen and (orientation: landscape) {
  #matrixRain {
    height: 6000px;
  }
}

#mfknLogo {
  position: absolute;
  top: 2.5%;
  left: 35%;
  height: 4%;
  width: 30%;
}

#twitch-embed {
  position: absolute;
  top: 9%;
  left: 1%;
  height: 14%;
  width: 98%;
}

#iframe {
  position: absolute;
  top: 9%;
}

.title {
  z-index: 3;
  position: absolute;
  background-color: white;
  text-align: left;
  top: 0%;
  left: 0%;
  height: 9%;
  width: 100%;
}

/* Media query for landscape orientation */
@media screen and (orientation: landscape) {
  .title {
    font-size: x-large;
  }
}

#aboutTextContainer {
  position: absolute;
  background-color: black;
  border-style: outset;
  top: 25.5%;
  left: 5%;
  width: 90%;
  height: 15%;
}

#aboutText {
  position: absolute;
  color: #cf102b;
  text-align: center;
  font-size: 2.5vw;
  top: 10%;
  width: 100%;
}

/* Media query for landscape orientation */
@media screen and (orientation: landscape) {
  #aboutText {
    font-size: 3vw;
  }
}

#discordicondiv {
  position: absolute;
  bottom: 3%;
  right: 15%;
  height: 12%;
  width: 10%;
}

#discordicon {
  height: 100%;
  width: 100%;
}

#instagramicondiv {
  position: absolute;
  bottom: 3%;
  left: 15%;
  height: 12%;
  width: 10%;
}

#instagramicon {
  height: 100%;
  width: 100%;
}

#tiktokicondiv {
  position: absolute;
  bottom: 3%;
  left: 45%;
  height: 12%;
  width: 10%;
}

#tiktokicon {
  height: 100%;
  width: 100%;
}

#storeContainer{
  position: absolute;
  border-style: outset;
  background-color: black;
  top: 43%;
  left: 5%;
  height: 15%;
  width: 90%;
}

#shopifyContainer {
  position: absolute;
  background-color: black;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

#storeText  {
  z-index: 2;
  top: 9%;
  position: absolute;
  color: #cf102b;
  font-size: 1vh;
}

/* Media query for landscape orientation */
@media screen and (orientation: landscape) {
  #storeText {
    font-size: 2vw;
  }
}

#contactForm {
  position: absolute;
  background-color: black;
  border-style: outset;
  font-size: 98%;
  text-align: center;
  top: 60%;
  left: 5%;
  height: 15%;
  width: 90%;
}

#fullname::placeholder,
#email::placeholder,
#message::placeholder {
    font-size: 16px; /* Adjust the font size as needed */
  }

/* Media query for landscape orientation */
@media screen and (orientation: landscape) {
  #fullname::placeholder,
  #email::placeholder,
  #message::placeholder {
    font-size: 32px; /* Adjust the font size as needed */
  }
}

#fullname {
  position: absolute;
  height: 7%;
  width: 80%;
  left: 7.5%;
  top: 15%;
}

#email {
  position: absolute;
  height: 7%;
  width: 80%;
  left: 7.5%;
  top: 30%;
}

#message {
  position: absolute;
  height: 30%;
  width: 80.5%;
  left: 7.5%;
  top: 45%;
  resize: none;
}

#submit {
  position: absolute;
  left: 35%;
  top: 90%;
  width: 30%;
}

#thankYouMessage {
  display: none;
  position: absolute;
  background-color: black;
  border-style: outset;
  top: 85%;
  left: 25%;
  height: 5%;
  width: 50%;
  color: #cf102b;
  text-align: center;
  font-size: small;
}

#thankYouMessageTitleArea {
  position: absolute;
  background: white;
  height: 20%;
  width: 100%;
  left: 0;
  top: 0;
}

p {
  position: absolute;
  top: 15%;
  left: 5%;
}

.closeButton {
  position: absolute;
  background-color: red;
  height: 96%;
  width: 10%;
  right: 0%;
  cursor: pointer;
}